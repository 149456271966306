import React, { useState } from "react";
import pdf from "../assets/Brochure.pdf";
import labstop from "../assets/img/lab setup_top.png";
import PortableLab from "../assets/img/portable lab setup.jpeg.jpg";
import TraditionalLab from "../assets/img/traditional labsetup.jpeg.jpg";
import GalleryLabs from "./GalleryLabs";
import ScrollToTop from "./ScrollToTop";

function Card({ title, content, imageUrl, pdfUrl }) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="flip-card" onClick={handleFlip}>
      <div className={`flip-card-inner ${isFlipped ? "flipped" : ""}`}>
        <div className="flip-card-front">
          <div className="card-image">
            <img id="OurCourseimg" src={imageUrl} alt={title} />
          </div>
          <strong className="OurCourse_title">{title}</strong>
        </div>
        <div className="flip-card-back">
          <p>{content}</p>
          <a
            href={pdfUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="download-button"
          >
            Download
          </a>
        </div>
      </div>
    </div>
  );
}

function OurLabSetup() {
  return (
    <>
      <ScrollToTop />
      <div className="OurCourses_Banner">
        <img src={labstop} className="coursehead_img" alt="labstop" />
        <div className="centered">Our LabSetup</div>
      </div>
      <div className="card-container">
        <Card
          title="Portable Lab"
          content="In a portable lab, students unlock the world of
          automation and innovation wherever they go..."
          imageUrl={PortableLab}
          pdfUrl={pdf}
        />
        <Card
          title="Traditional
          Lab"
          content="A traditional lab for education provides a dedicated
          and well-equipped space within an institution,
          offering students a fixed environment to delve into
          projects..."
          imageUrl={TraditionalLab}
          pdfUrl={pdf}
        />
      </div>
      <div className="OurProjects">
        <h3 className="OurProjects_H3">
          Explore Our Robotics Lab: A Fusion of Innovation and Creativity!
        </h3>
        <p className="OurProjects_P">
          Step into our robotics lab and witness the future taking shape. Our 3D
          design showcases the precision, complexity, and ingenuity of our
          projects. From concept to creation, we push the boundaries of what's
          possible in robotics. Join us on this journey of innovation.
        </p>
        <GalleryLabs />
      </div>
    </>
  );
}

export default OurLabSetup;

import React from "react";
import { Helmet } from "react-helmet";
import { Banner } from "./components/Banner";
import Contact from "./components/Contact";
import Service from "./components/Service";

function Home() {
  return (
    <>
      <Helmet>
        <title>RoboticIO</title>
        <meta
          name="description"
          content="Embark on an exhilarating journey into the future of STEM Education with RoboticIO! We're not just educators; we're architects of dreams, empowering the leaders, thinkers, and creators of tomorrow. Dive into the world of Robotics, Coding, Drone Technology, and Augmented Reality (AR) with our innovative programs designed for schools. Explore hands-on workshops, cutting-edge labs, and immersive experiences that inspire and ignite curiosity in the next generation of tech enthusiasts."
        />
        <meta
          name="keywords"
          content="Roboticio,RoboticIO,roboticio,roboticIO,Robotics, Coding, Drone, AR/VR ,School ,Education ,Lab ,Workshop,STEM Education, Robotics Education, Coding Programs , Drone Technology , Augmented Reality (AR) Workshops , Future Tech Education , Innovative Learning , Hands-on Robotics , STEM Workshops for Schools , Coding Classes for Kids , Drone Workshops , AR Education , Tech Enrichment Programs, Educational Robotics Kits , STEM Labs , Robotics Curriculum , Robotics Curriculum , Drone Curriculum , AR/VR Education , Technology Empowerment"
        />
      </Helmet>
      <Banner />

      <Service />

      <Contact />
    </>
  );
}

export default Home;

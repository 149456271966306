import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import About from "./components/About";
import Button from "./components/Button";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Fzf from "./components/Fzf";
import { NavBar } from "./components/NavBar";
import OurCourses from "./components/OurCourses";
import OurLabSetup from "./components/OurLabSetup";
import OurWorkshops from "./components/OurWorkshops";
import School from "./components/School";
import Service from "./components/Service";

const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Service />} />
          <Route path="/ourCourses" element={<OurCourses />} />
          <Route path="/ourworkshops" element={<OurWorkshops />} />
          <Route path="/ourlabsetup" element={<OurLabSetup />} />
          <Route path="/about" element={<About />} />
          <Route path="/school" element={<School />} />
          <Route path="/404" element={<Fzf />} />
        </Routes>
        <Button />
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;

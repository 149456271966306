import React from "react";
import errorimage from "../assets/img/Course 1_robotics.png";
import ScrollToTop from "./ScrollToTop";

const Fzf = () => {
  return (
    <>
      <ScrollToTop />
      <div className="construction-container">
        <div className="text-container">
          <h1>Under Construction</h1>
          <p>We are currently working on this page. Please check back later.</p>
          <button>
            <a className="constimg" href="/">
              Go Back to Home
            </a>
          </button>
        </div>
        <div className="image-container">
          <img
            src={errorimage}
            alt="Under Construction"
            className="construction-image"
          />
        </div>
      </div>
    </>
  );
};

export default Fzf;

import React, { useState } from "react";
import pdf from "../assets/Brochure.pdf";
import LinefollowerRobot from "../assets/img/LFR.png";
import workshopTop from "../assets/img/Workshop_top_1.png";
import BasicsofCoding from "../assets/img/coding_workshop.png";
import Drone from "../assets/img/drone_1.png";
import RoboticDog from "../assets/img/robotic_dog.png";
import Gallery from "./Gallery";
import ScrollToTop from "./ScrollToTop";

function Card({ title, content, imageUrl, pdfUrl }) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="flip-card" onClick={handleFlip}>
      <div className={`flip-card-inner ${isFlipped ? "flipped" : ""}`}>
        <div className="flip-card-front">
          <div className="card-image">
            <img id="OurCourseimg" src={imageUrl} alt={title} />
          </div>
          <strong className="OurCourse_title">{title}</strong>
        </div>
        <div className="flip-card-back">
          <p>{content}</p>
          <a
            href={pdfUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="download-button"
          >
            Download
          </a>
        </div>
      </div>
    </div>
  );
}

function OurWorkshops() {
  return (
    <>
      <ScrollToTop />
      <div className="OurCourses_Banner">
        <img src={workshopTop} alt="Course Head" className="coursehead_img" />
        <div className="centered">Our Workshops</div>
      </div>
      <div className="card-container">
        <Card
          title="Line follower Robot"
          content="In this hands-on workshop, Participants will
          learn the fundamentals of communication..."
          imageUrl={LinefollowerRobot}
          pdfUrl={pdf}
        />
        <Card
          title="Robotic Dog"
          content="The participants engage in a hands-on learning
          experience that spans mechanical assembly & 
          electronics integration..."
          imageUrl={RoboticDog}
          pdfUrl={pdf}
        />
        <Card
          title="Basics of Coding "
          content="In the captivating realm of a coding basics
          workshop, participants embark on a journey into
          the language of programming..."
          imageUrl={BasicsofCoding}
          pdfUrl={pdf}
        />
        <Card
          title="Drone"
          content="Participants engineer their skyward companions.
          They piece together propellers, motors, and
          sensors, breathing life into their aerial creations..."
          imageUrl={Drone}
          pdfUrl={pdf}
        />
      </div>
      <div className="OurProjects">
        <h3 className="OurProjects_H3">
          Unleash Your Creativity with Our DIY Robotics Projects!
        </h3>
        <p className="OurProjects_P">
          Explore our range of DIY robotics projects, designed to ignite your
          creativity and passion for innovation. From beginner-friendly builds
          to advanced challenges, our projects offer hands-on learning
          experiences for enthusiasts of all levels. Join our vibrant community,
          where you can connect with like-minded individuals, share ideas, and
          showcase your creations. Start your robotics journey today and unlock
          endles possibilities!
        </p>
        <Gallery />
      </div>
    </>
  );
}

export default OurWorkshops;

// import { Container, Row, Col, Tab, Nav } from "react-bootstrap";

// import "animate.css";
// import TrackVisibility from "react-on-screen";
// import Courses from "./Courses";

// export const Service = () => {

//   return (
//     <section className="project" id="projects">
//       <Container>
//         <Row>
//           <Col size={12}>
//             <TrackVisibility>
//               {({ isVisible }) => (
//                 <div
//                   className={
//                     isVisible ? "animate__animated animate__fadeIn" : ""
//                   }>
//                   <h2>Our Services</h2>

//                   <Tab.Container id="projects-tabs" defaultActiveKey="first">
//                     <Nav
//                       variant="pills"
//                       className="nav-pills mb-5 justify-content-center align-items-center"
//                       id="pills-tab">
//                       <Nav.Item>
//                       <Nav.Link  eventKey="first">Courses </Nav.Link>
//                       </Nav.Item>
//                       <Nav.Item>
//                         <Nav.Link eventKey="second"> WorkShop </Nav.Link>
//                       </Nav.Item>
//                       <Nav.Item>
//                         <Nav.Link eventKey="third">Labs</Nav.Link>
//                       </Nav.Item>
//                     </Nav>
//                     <Tab.Content
//                       id="slideInUp"
//                       className={
//                         isVisible ? "animate__animated animate__slideInUp" : ""
//                       }>
//                       <Tab.Pane eventKey="first">
//                         <Row>
//                         <Courses/>
//                         </Row>

//                       </Tab.Pane>
//                       <Tab.Pane eventKey="second">
//                         <Row>
//                         <Courses/>
//                         </Row>

//                       </Tab.Pane>
//                       <Tab.Pane eventKey="third">
//                         <Row>
//                         <Courses/>
//                         </Row>

//                       </Tab.Pane>
//                     </Tab.Content>
//                   </Tab.Container>
//                 </div>
//               )}
//             </TrackVisibility>
//           </Col>
//         </Row>
//       </Container>
//     </section>
//   );
// };

import "animate.css";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TrackVisibility from "react-on-screen";
import Courses from "./Courses";

const Service = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              <Courses />
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Service;

import React from "react";

const OurMethodology = () => {
  return (
    <div>
      <div className="Our">Our</div>
      <div className="About_Methodology">Methodology</div>
      <br />
      <div className="about_container">
        <div className="about_main-timeline">
          <div className="about_timeline">
            <div className="about_icon" />
            <div className="about_date-content">
              <div className="about_date-outer">
                <span className="about_date">
                  <span className="about_year">1</span>
                </span>
              </div>
            </div>
            <div className="about_timeline-content">
              <h5 className="about_title">Delivery of Concept</h5>
              <p className="about_description">
                Delivering quality content to the students with the assistance
                of trained instructors.
              </p>
            </div>
          </div>

          <div className="about_timeline">
            <div className="about_icon" />
            <div className="about_date-content">
              <div className="about_date-outer">
                <span className="about_date">
                  <span className="about_year">2</span>
                </span>
              </div>
            </div>
            <div className="about_timeline-content">
              <h5 className="about_title">Activities</h5>
              <p className="about_description">
                Enhancing Student Learning with Engaging Activities, Our aim is
                to make learning easier and enjoyable.
              </p>
            </div>
          </div>

          <div className="about_timeline">
            <div className="about_icon" />
            <div className="about_date-content">
              <div className="about_date-outer">
                <span className="about_date">
                  <span className="about_year">3</span>
                </span>
              </div>
            </div>
            <div className="about_timeline-content">
              <h5 className="about_title">Circuit Designing & Coding</h5>
              <p className="about_description">
                Comprehending the Idea Through Circuit Preparation and Code
                Implementation with Expert Guidance.
              </p>
            </div>
          </div>

          <div className="about_timeline">
            <div className="about_icon" />
            <div className="about_date-content">
              <div className="about_date-outer">
                <span className="about_date">
                  <span className="about_year">4</span>
                </span>
              </div>
            </div>
            <div className="about_timeline-content">
              <h5 className="about_title">Projects</h5>
              <p className="about_description">
                Learning by Doing: Gaining a Real-Time Understanding of How
                Things Work through Project- Making.
              </p>
            </div>
          </div>

          <div className="about_timeline">
            <div className="about_icon" />
            <div className="about_date-content">
              <div className="about_date-outer">
                <span className="about_date">
                  <span className="about_year">5</span>
                </span>
              </div>
            </div>
            <div className="about_timeline-content">
              <h5 className="about_title">Assessment</h5>
              <p className="about_description">
                Students can monitor their educational progress by conducting
                self- assessments.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMethodology;

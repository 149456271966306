import axios from "axios";
import React, { useState } from "react";
import {
  FaEnvelope,
  FaInstagram,
  FaLinkedinIn,
  FaPhone,
  FaWhatsapp,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import brochuer from "../assets/Brochure.pdf";
import logo from "../assets/img/Logo_Blue_Transparent_BG.png";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [subscriptionDone, setSubscriptionDone] = useState(false);

  const handleSubscribe = () => {
    if (email.trim() !== "") {
      const data = {
        Email: email,
      };

      axios
        .post("https://sheetdb.io/api/v1/nowd1fe26keu3", data)
        .then((response) => {
          console.log(response);
          setEmail("");
          setSubscriptionDone(true);
        })
        .catch((error) => {
          console.error("Error occurred:", error);
        });
    } else {
      alert("Please enter a valid email address.");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <footer>
      <div className="content">
        <div className="top">
          <div className="logo-details">
            <span className="logo_name">
              <img className="footer_logo" src={logo} alt="RoboticIO" />
            </span>
          </div>
          <div className="media-icons">
            <a href="https://api.whatsapp.com/send?phone=9241714345">
              <FaWhatsapp />
            </a>
            <a href="https://www.linkedin.com/company/roboticio/">
              <FaLinkedinIn />
            </a>
            <a href="https://www.instagram.com/robotic_io?igsh=MTZmbWU2bncwdTJ1bg==">
              <FaInstagram />
            </a>
          </div>
        </div>
        <div>
          <p id="footerP">Empowering minds for Tomorrow’s world</p>
        </div>
        <div className="link-boxes">
          <ul className="box">
            <li className="link_name">Company</li>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/contact">Contact us</Link>
            </li>
            <li>
              <Link to="/about">About us</Link>
            </li>
          </ul>
          <ul className="box">
            <li className="link_name">Services</li>
            <li>
              <Link to="/ourCourses">Courses</Link>
            </li>
            <li>
              <Link to="/ourworkshops">Workshops</Link>
            </li>
            <li>
              <Link to="/ourlabsetup">Lab Setup</Link>
            </li>
          </ul>
          <ul className="box">
            <li className="link_name">Resources</li>
            <li>
              <a href={brochuer}>Brochure</a>
            </li>
          </ul>
          <ul className="box">
            <li>
              <a href="mailto:info.roboticio@gmail.com">
                <FaEnvelope size={20} />
                info.roboticio@gmail.com
              </a>
              <br />
              <a href="tel:+91 92417 14345">
                <FaPhone size={20} />
                +91 92417 14345
              </a>
            </li>
          </ul>
          <ul className="box input-box">
            <li className="link_name">Subscribe</li>
            <li>
              <input
                id="email"
                type="text"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
              />
            </li>
            <li>
              <button onClick={handleSubscribe}>
                {subscriptionDone ? "Done" : "Subscribe"}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="bottom-details">
        <div className="bottom_text">
          <span className="copyright_text">
            Copyright © 2024 <a href="/">RoboticIO.</a>All rights reserved
          </span>
          <span className="policy_terms">
            <Link to="/404">Privacy policy</Link>
            <Link to="/404">Terms & condition</Link>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

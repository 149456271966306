import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import courses from "../assets/img/Course 1_robotics.png";
import labs from "../assets/img/lab setup_front_2.png";
import workshops from "../assets/img/workshop_front_1.png";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Courses = () => {
  return (
    <>
      <ScrollToTop />
      <div>
        <h3 id="services-h3">Our Services</h3>
      </div>

      <div className="cwl">
        <div className="cwl-card">
          <div className="card">
            <div className="imgBx">
              <img src={courses} alt="courses" />
            </div>
            <div className="content">
              <h2>Courses</h2>
              <p id="p1">
                In a robotics class, students embark on a hands-on journey,
                assembling and programming robots...
              </p>
              <Link to="/ourCourses">
                <button className="btn">Our Courses</button>
              </Link>
            </div>
          </div>
          <div className="card">
            <div id="workshopimg" className="imgBx">
              <img src={workshops} alt="workshops" />
            </div>
            <div className="content">
              <h2>Workshops</h2>
              <p id="p1">
                In this hands-on workshop, Participants will learn the
                fundamentals of communication...
              </p>
              <Link to="/ourworkshops">
                <button className="btn">Our Workshops</button>
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="imgBx">
              <img src={labs} alt="labs" />
            </div>
            <div className="content">
              <h2>Lab Setup</h2>
              <p id="p1">
                In a portable lab, students unlock the world of automation and
                innovation wherever they go.
              </p>
              <Link to="/ourlabsetup">
                <button className="btn">Our Labs</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Courses;

import React, { useEffect, useState } from "react";
import { SocialIcon } from "react-social-icons";

function Button() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleClick = () => {
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 300);
  };

  return (
    <div
      className={`whatsapp_Btn ${isScrolled ? "scrolled" : ""}`}
      onClick={handleClick}
    >
      <div className="whatsapp_svgContainer">
        <SocialIcon
          url="https://api.whatsapp.com/send?phone=9241714345"
          network="whatsapp"
          bgColor="#075e54"
          fgColor="#ffffff"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "10px",
            boxShadow: "none",
            marginRight: "10px",
            padding: "23px",
          }}
        />
      </div>
      <span className={`whatsapp_BG ${isClicked ? "rotate" : ""}`}></span>
    </div>
  );
}

export default Button;

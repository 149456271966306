import React from "react";

const KeyFeatures = () => {
  return (
    <div>
      <div className="key">Key</div>
      <div className="Feature">Features</div>
      <br />
      <div className="Key_container">
        <div className="main-timeline">
          <div className="timeline">
            <div className="icon" />
            <div className="date-content">
              <div className="date-outer">
                <span className="date">
                  <span className="year">1</span>
                </span>
              </div>
            </div>
            <div className="timeline-content">
              <h5 className="title">Career Exploration</h5>
              <p className="description">
                Engaging with robotics & coding at a young age can spark
                interest in STEM-related careers, such as robotics engineering,
                artificial intelligence, Web developer, software developer and
                many more.
              </p>
            </div>
          </div>
          <div className="timeline">
            <div className="icon" />
            <div className="date-content">
              <div className="date-outer">
                <span className="date">
                  <span className="year">2</span>
                </span>
              </div>
            </div>
            <div className="timeline-content">
              <h5 className="title">Engineering Principles</h5>
              <p className="description">
                Students gain insights into engineering principles such as
                coding, mechanics, electronics, and control systems through the
                practical application of building and programming.
              </p>
            </div>
          </div>
          <div className="timeline">
            <div className="icon" />
            <div className="date-content">
              <div className="date-outer">
                <span className="date">
                  <span className="year">3</span>
                </span>
              </div>
            </div>
            <div className="timeline-content">
              <h5 className="title">Real World Application</h5>
              <p className="description">
                These programs frequently mimic real-world uses, showing how
                these are applied in a variety of sectors, including
                manufacturing, healthcare, space exploration and many more.
              </p>
            </div>
          </div>

          <div className="timeline">
            <div className="icon" />
            <div className="date-content">
              <div className="date-outer">
                <span className="date">
                  <span className="year">4</span>
                </span>
              </div>
            </div>
            <div className="timeline-content">
              <h5 className="title">Programming Proficiency</h5>
              <p className="description">
                This type of activities introduces students to programming
                concepts, helping them become proficient in different
                programming languages such as Python or C++.
              </p>
            </div>
          </div>

          <div className="timeline">
            <div className="icon" />
            <div className="date-content">
              <div className="date-outer">
                <span className="date">
                  <span className="year">5</span>
                </span>
              </div>
            </div>
            <div className="timeline-content">
              <h5 className="title">Critical Thinking & Problem Solving</h5>
              <p className="description">
                It requires logical thinking and problem-solving skills which
                can help students develop these abilities as they learn to break
                down complex problems into smaller, more manageable tasks.
              </p>
            </div>
          </div>

          <div className="timeline">
            <div className="icon" />
            <div className="date-content">
              <div className="date-outer">
                <span className="date">
                  <span className="year">6</span>
                </span>
              </div>
            </div>
            <div className="timeline-content">
              <h5 className="title">Creativity Enhancement</h5>
              <p className="description">
                Designing and customizing encourages creativity, allowing
                students to explore innovative solutions and express their
                imagination.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyFeatures;
// import React from "react";

// const KeyFeatures = () => {
//   return (
//     <div>
//       <div className="Our">Key</div>
//       <div className="About_Methodology">Features</div>
//       <br />
//       <div className="about_container">
//         <div className="about_main-timeline">
//           <div className="about_timeline">
//             <div className="about_icon" />
//             <div className="about_date-content">
//               <div className="about_date-outer">
//                 <span className="about_date">
//                   <span className="about_year">1</span>
//                 </span>
//               </div>
//             </div>
//             <div className="about_timeline-content">
//               <h5 className="about_title">Career Exploration</h5>
//               <p className="about_description">
//                 Engaging with robotics & coding at a young age can spark
//                 interest in STEM-related careers, such as robotics engineering,
//                 artificial intelligence, Web developer, software developer and
//                 many more.
//               </p>
//             </div>
//           </div>

//           <div className="about_timeline">
//             <div className="about_icon" />
//             <div className="about_date-content">
//               <div className="about_date-outer">
//                 <span className="about_date">
//                   <span className="about_year">2</span>
//                 </span>
//               </div>
//             </div>
//             <div className="about_timeline-content">
//               <h5 className="about_title">Engineering Principles</h5>
//               <p className="about_description">
//                 Students gain insights into engineering principles such as
//                 coding, mechanics, electronics, and control systems through the
//                 practical application of building and programming.
//               </p>
//             </div>
//           </div>

//           <div className="about_timeline">
//             <div className="about_icon" />
//             <div className="about_date-content">
//               <div className="about_date-outer">
//                 <span className="about_date">
//                   <span className="about_year">3</span>
//                 </span>
//               </div>
//             </div>
//             <div className="about_timeline-content">
//               <h5 className="about_title">Real World Application</h5>
//               <p className="about_description">
//                 These programs frequently mimic real-world uses, showing how
//                 these are applied in a variety of sectors, including
//                 manufacturing, healthcare, space exploration and many more.
//               </p>
//             </div>
//           </div>

//           <div className="about_timeline">
//             <div className="about_icon" />
//             <div className="about_date-content">
//               <div className="about_date-outer">
//                 <span className="about_date">
//                   <span className="about_year">4</span>
//                 </span>
//               </div>
//             </div>
//             <div className="about_timeline-content">
//               <h5 className="about_title">Programming Proficiency</h5>
//               <p className="about_description">
//                 This type of activities introduces students to programming
//                 concepts, helping them become proficient in different
//                 programming languages such as Python or C++.
//               </p>
//             </div>
//           </div>

//           <div className="about_timeline">
//             <div className="about_icon" />
//             <div className="about_date-content">
//               <div className="about_date-outer">
//                 <span className="about_date">
//                   <span className="about_year">5</span>
//                 </span>
//               </div>
//             </div>
//             <div className="about_timeline-content">
//               <h5 className="about_title">
//                 Critical Thinking & Problem Solving
//               </h5>
//               <p className="about_description">
//                 It requires logical thinking and problem-solving skills which
//                 can help students develop these abilities as they learn to break
//                 down complex problems into smaller, more manageable tasks.
//               </p>
//             </div>
//           </div>
//           <div className="about_timeline">
//             <div className="about_icon" />
//             <div className="about_date-content">
//               <div className="about_date-outer">
//                 <span className="about_date">
//                   <span className="about_year">6</span>
//                 </span>
//               </div>
//             </div>
//             <div className="about_timeline-content">
//               <h5 className="about_title">Creativity Enhancement</h5>
//               <p className="about_description">
//                 Designing and customizing encourages creativity, allowing
//                 students to explore innovative solutions and express their
//                 imagination.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default KeyFeatures;

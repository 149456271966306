import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/img/Logo_Blue_Transparent_BG.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const NavBar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Navbar expand="md" className={`navbar ${scrolled ? "scrolled" : ""}`}>
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img id="img" src={logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <NavItem to="/" exact>
              Home
            </NavItem>
            <NavItem to="/about">About</NavItem>
            <NavItem to="/school">School</NavItem>
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              <a href="https://www.linkedin.com/company/roboticio/">
                <img src={navIcon1} alt="" />
              </a>
              <a href="https://api.whatsapp.com/send?phone=9241714345">
                <img src={navIcon2} alt="" />
              </a>
              <a href="https://www.instagram.com/robotic_io?igsh=MTZmbWU2bncwdTJ1bg==">
                <img src={navIcon3} alt="" />
              </a>
            </div>
            <a href="/contact">
              <button className="vvd">
                <span>Let’s Connect</span>
              </button>
            </a>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const NavItem = ({ to, exact, children }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Nav.Item>
      <Nav.Link
        as={NavLink}
        to={to}
        exact={exact}
        className="nav-link navbar-link"
        onClick={scrollToTop}
      >
        {children}
      </Nav.Link>
    </Nav.Item>
  );
};

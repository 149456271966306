import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import schoolimg from "../assets/img/course-img1.png";
import schoolimg2 from "../assets/img/course-img2.png";
import schoolimg3 from "../assets/img/course-img3.png";
import KeyFeatures from "./KeyFeatures";
import Service from "./Service";

const School = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1));
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <section id="school_img" >
        <Carousel activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item>
            <img className="d-block w-100" src={schoolimg} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={schoolimg2}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={schoolimg3} alt="Third slide" />
          </Carousel.Item>
        </Carousel>
      </section>
      <Service />
      <KeyFeatures />
    </>
  );
};

export default School;

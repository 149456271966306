import React, { useState } from "react";
import pdf from "../assets/Brochure.pdf";
import CourseHead from "../assets/img/Courses_top.png";
import codingImage from "../assets/img/coding_1.png";
import droneImage from "../assets/img/drone_1.png";
import roboticsImage from "../assets/img/robotics_1.png";
import ScrollToTop from "./ScrollToTop";

import Gallery from "./Gallery";

function Card({ title, content, imageUrl, pdfUrl }) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="flip-card" onClick={handleFlip}>
      <div className={`flip-card-inner ${isFlipped ? "flipped" : ""}`}>
        <div className="flip-card-front">
          <div className="card-image">
            <img id="OurCourseimg" src={imageUrl} alt={title} />
          </div>
          <strong className="OurCourse_title">{title}</strong>
        </div>
        <div className="flip-card-back">
          <p>{content}</p>
          <a
            href={pdfUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="download-button"
          >
            Download
          </a>
        </div>
      </div>
    </div>
  );
}

function OurCourses() {
  return (
    <>
      <ScrollToTop />
      <div className="OurCourses_Banner">
        <img src={CourseHead} alt="Course Head" className="coursehead_img" />
        <div className="centered">Our Courses</div>
      </div>
      <div className="card-container">
        <Card
          title="Robotics"
          content="In a robotics class, students embark on a hands-on journey, assembling and programming robots..."
          imageUrl={roboticsImage}
          pdfUrl={pdf}
        />
        <Card
          title="Coding"
          content="In a coding class, students dive into the language of computers, learning programming basics..."
          imageUrl={codingImage}
          pdfUrl={pdf}
        />
        <Card
          title="Drone"
          content="In a drone-making class, students explore hands-on aeronautical engineering by assembling drone components..."
          imageUrl={droneImage}
          pdfUrl={pdf}
        />
      </div>
      <div className="OurProjects">
        <h3 className="OurProjects_H3">
          Unleash Your Creativity with Our DIY Robotics Projects!
        </h3>
        <p className="OurProjects_P">
          Explore our range of DIY robotics projects, designed to ignite your
          creativity and passion for innovation. From beginner-friendly builds
          to advanced challenges, our projects offer hands-on learning
          experiences for enthusiasts of all levels. Join our vibrant community,
          where you can connect with like-minded individuals, share ideas, and
          showcase your creations. Start your robotics journey today and unlock
          endles possibilities!
        </p>
        <Gallery />
      </div>
    </>
  );
}

export default OurCourses;

import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";
import ourProject1 from "../assets/img/ourproject11.jpg";
import ourProject12 from "../assets/img/ourproject12.jpg";
import ourProject2 from "../assets/img/ourprojects2.jpg";
import ourProject3 from "../assets/img/ourprojects3.jpg";
import ourProject5 from "../assets/img/ourprojects5.jpg";

export default function Gallery() {
  return (
    <>
      <div className="OurProjectGallery">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          loop={true}
          coverflowEffect={{
            rotate: 20,
            stretch: 0,
            depth: 700,
            modifier: 1,
            slideShadows: true,
          }}
          autoplay={{
            delay: 700,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, EffectCoverflow, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide className="OurProject_img">
            <img src={ourProject1} alt="ourProject1" />
          </SwiperSlide>
          <SwiperSlide className="OurProject_img">
            <img src={ourProject2} alt="ourProject1" />
          </SwiperSlide>
          <SwiperSlide className="OurProject_img">
            <img src={ourProject3} alt="ourProject1" />
          </SwiperSlide>
          <SwiperSlide className="OurProject_img">
            <img src={ourProject12} alt="ourProject1" />
          </SwiperSlide>
          <SwiperSlide className="OurProject_img">
            <img src={ourProject5} alt="ourProject1" />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}

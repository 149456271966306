import "animate.css";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TrackVisibility from "react-on-screen";
import Goal from "../assets/img/Goal.png";
import headerImg from "../assets/img/img4.png";
import mission from "../assets/img/mission.png";
import vision from "../assets/img/vision.png";
import OurMethodology from "./OurMethodology";

import Button from "./Button";
import ScrollToTop from "./ScrollToTop";

const About = () => {
  return (
    <>
      <ScrollToTop />
      <section className="banner">
        <Container>
          <Row className="aligh-items-center">
            <Col xs={12} md={6} xl={7}>
              <TrackVisibility>
                {({ isVisible }) => (
                  <div
                    className={
                      isVisible ? "animate__animated animate__fadeIn" : ""
                    }
                  >
                    <span className="tagline">ABOUT US</span>
                    <div>
                      <strong>Shaping Tomorrow's Innovators!</strong>

                      <p className="about-p">
                        Embark on an exhilarating journey into the future of
                        Technology Education with RoboticIO! We're not just
                        educators; we're architects of dreams, empowering the
                        leaders, thinkers, and creators of tomorrow.
                      </p>
                    </div>
                    <br />

                    <div>
                      <strong>What Sets Us Apart:</strong>

                      <p className="about-p">
                        Immerse yourself in the pulse of innovation! At
                        RoboticIO, we transform ordinary learning into
                        extraordinary experiences. Through hands-on robotics
                        adventures and immersive tech courses, we ignite sparks
                        of curiosity and fuel the flames of ingenuity.
                      </p>
                    </div>
                    <div>
                      <div className="bolt">
                        <strong>
                          Welcome to RoboticIO- Where Innovation meets Education
                        </strong>
                      </div>
                    </div>
                  </div>
                )}
              </TrackVisibility>
            </Col>
            <Col xs={12} md={6} xl={5}>
              <TrackVisibility>
                {({ isVisible }) => (
                  <div
                    className={
                      isVisible ? "animate__animated animate__zoomIn" : ""
                    }
                  >
                    <img
                      className="banner-img2"
                      src={headerImg}
                      alt="Header Img"
                    />
                  </div>
                )}
              </TrackVisibility>
            </Col>
          </Row>
        </Container>
        <main>
          <h3 id="about_h3">What Sets Us Apart</h3>
          <div className="about_container">
            <div className="about_card card-1">
              <div className="about_card-header">
                <img src={Goal} class="about_card-img" alt="Goal" />
              </div>
              <div className="about_card-body">
                <h3 className="about_card-title">Goal</h3>
                <p id="goal">
                  "Empowering Learners for a Tech-Powered Tomorrow"
                </p>
              </div>
            </div>
            <div className="about_card card-2">
              <div className="about_card-header">
                <img src={vision} className="about_card-img " alt="vision" />
              </div>
              <div className="about_card-body">
                <h3 className="about_card-title">Vision</h3>
                <p className="about_card-text">
                  “At RoboticIO, our vision is to be a leader in shaping the
                  future of education. We envision a world where every student
                  has access to high-quality, personalized learning experiences
                  that prepare them not only for the challenges of tomorrow but
                  empower them to be the creators, innovators, and leaders of
                  the technological age.”
                </p>
              </div>
            </div>
            <div className="about_card card-3">
              <div className="about_card-header">
                <img src={mission} class="about_card-img" alt="mission" />
              </div>
              <div className="about_card-body">
                <h3 className="about_card-title">Mission</h3>
                <p className="about_card-text">
                  "At RoboticIO, our mission is to empower students for the
                  future through modern education technologies. We're committed
                  to fostering innovation and critical thinking, ensuring every
                  student is future-ready.”
                </p>
              </div>
            </div>
          </div>
        </main>
        <div>
          <OurMethodology />
        </div>
      </section>
      <Button />
    </>
  );
};

export default About;
